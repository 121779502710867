import styled from 'styled-components';

const SRessources = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
  font-size: 6vw;
  color: white;
  background-color: black;
  -webkit-text-stroke: 3px white;
  -webkit-text-fill-color: transparent;
  @media screen and (max-width: 768px) {
    height: 80vh;
    font-size: 8.5vw;
  }
`;

export default SRessources;
