import styled from 'styled-components';

const SHeader = styled.header`
  .header {
    position: fixed;
    top: 0;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: black;
    height: 20vh;
    z-index: 10;
    font-size: 2vw;
    font-weight: bold;
    text-transform: Uppercase;
  }

  .small {
    height: 10vh;
    transition: 0.5s ease-in-out;
    -webkit-box-shadow: 0x 0px 10px 4px #4b9f83;
    box-shadow: 0px 0px 15px 4px #4b9f83;
  }
  .changeTextColor {
    transition: 0.5s ease-in-out;
    color: white !important;
  }
  .changeTextColor:hover {
    transition: none;
    color: #4b9f83 !important;
    text-decoration: none !important;
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #49ff18,
      0 0 30px #49ff18, 0 0 40px #49ff18, 0 0 55px #49ff18, 0 0 75px #49ff18,
      2px 2px 2px rgba(75, 159, 131, 0) !important;
  }
  .logo {
    height: auto;
    width: 12vw;
    margin-left: 10vw;
    margin-top: 7vw;
    transform: rotate(-14deg);
  }
  .placeLogo {
    transition: 0.5s ease-in-out;
    transform: rotate(0);
    margin-top: 0;
    margin-left: 12vw;
    height: 8vh;
    width: 8vh;
    filter: none;
  }
  ul {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 45vw;
    margin-right: 10vh;
  }

  .navLink {
    display: flex;

    color: #6e646492;
    text-decoration: none;
    align-items: center;
  }
  .icon {
    padding-bottom: 5px;
    height: 45px;
    width: 45px;
  }
  .logo:hover {
    transform: scale(1.03) rotate(-12deg);
  }
  .navLink:hover {
    color: #4b9f83;
    text-decoration: none;
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #49ff18,
      0 0 30px #49ff18, 0 0 40px #49ff18, 0 0 55px #49ff18, 0 0 75px #49ff18,
      2px 2px 2px rgba(75, 159, 131, 0);
  }
  .icon:hover {
    color: #d4654b;
  }

  @media screen and (max-width: 768px) {
    .header {
      height: 10vh;
      -webkit-box-shadow: 0x 0px 10px 4px #4b9f83;
      box-shadow: 0px 0px 15px 4px #4b9f83;
      font-size: 2.7vw;
    }
    .logo {
      transform: rotate(0);
      margin-top: 0;
      margin-left: 3vw;
      margin-right: 5vw;
      height: 8vh;
      width: 8vh;
      filter: none;
    }
    .navLink {
      color: #fff;
      margin-right: 4px;
    }
    ul {
      margin-right: 5vw;
      width: 90vw;
    }
    .icon {
      margin-left: 3vw;
      height: 60px;
      width: 60px;
    }
  }
`;

export default SHeader;
