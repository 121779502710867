import styled from 'styled-components';

const SvideosList = styled.section`
  margin-top: 100px;
  color: white;
  display: flex;
  flex-direction: column;
  h1 {
    font-size: 3rem;
    margin-bottom: 100px;
    text-transform: uppercase;
    font-weight: bolder;
    text-align: center;
  }
  h2 {
    font-size: 2rem;
    margin-bottom: 15px;
  }

  align-items: center;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    180deg,
    #000000 20%,
    rgba(56, 83, 74, 1) 80%,
    #4f796b 92%,
    rgba(75, 159, 131, 1) 100%
  );

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .clip {
    width: 45vw;
    margin-bottom: 50px;
  }
  .clip:hover {
    transition: 0.3s ease-in;
    background: #4b9f83;
    box-shadow: 0 0 5px #fff, 0 0 10px #4b9f83, 0 0 15px #4b9f83,
      0 0 20px #4b9f83, 0 0 30px #4b9f83, 0 0 40px #4b9f83, 0 0 55px #4b9f83,
      0 0 75px #4b9f83, 2px 2px 2px rgba(75, 159, 131, 0);
  }

  @media screen and (max-width: 768px) {
    margin-top: 50px;
    .clip {
      width: 90vw;
      margin-bottom: 25px;
    }
    h1 {
      font-size: 25px;
      margin-bottom: 50px;
      text-align: center;
      text-transform: uppercase;
      z-index: 1;
    }
    h2 {
      font-size: 16px;
      margin-bottom: 15px;
      text-transform: uppercase;
    }
  }
`;

export default SvideosList;
