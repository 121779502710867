import styled from 'styled-components';

const SRules = styled.main`
  color: white;
  padding: 35vh 20vw 10vh 20vw;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    180deg,
    #000000 20%,
    rgba(56, 83, 74, 1) 80%,
    rgba(75, 159, 131, 1) 100%
  );
  div {
    height: auto;
    margin-bottom: 70px;
  }
  h1 {
    font-size: 5vw;

    text-transform: uppercase;
  }
  h2 {
    font-size: 2vw;
    margin-bottom: 30px;
    text-transform: uppercase;
  }
  p {
    font-size: 1.2vw;
  }
  ul {
    padding-left: 50px;
    line-height: 30px;
    margin-bottom: 30px;
  }
  a {
    display: flex;
    justify-content: center;
    text-decoration: none;
    color: black;
  }
  button {
    margin-top: 4vh;
    height: 50px;
    width: 14vw;
    background: white;
    border-radius: 50px;
    border: none;
    box-shadow: 0 0 5px #fff, 0 0 10px #645f5ff6, 0 0 15px #645f5ff6,
      0 0 20px #645f5ff6, 0 0 30px #645f5ff6, 0 0 40px #645f5ff6,
      0 0 55px #645f5ff6, 0 0 75px #645f5ff6;
    text-transform: uppercase;
  }
  button:hover {
    transform: scale(1.05);
    transition: 0.3s ease-in;
    background: #4b9f83;
    box-shadow: 0 0 5px #fff, 0 0 10px #4b9f83, 0 0 15px #4b9f83,
      0 0 20px #4b9f83, 0 0 30px #4b9f83, 0 0 40px #4b9f83, 0 0 55px #4b9f83,
      0 0 75px #4b9f83, 2px 2px 2px rgba(75, 159, 131, 0);
  }
  .download {
    display: flex;
    justify-content: flex-end;
  }
  .glitch {
    position: relative;
    color: #ffffff;
    z-index: 1;
  }

  . .glitch:before {
    content: attr(data-glitch);
    animation: noise-before 3s infinite linear alternate-reverse,
      glitch-color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both infinite;
    top: 0;
    left: -2px;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    color: #38534a;
    z-index: -1;
  }

  .glitch:after {
    content: attr(data-glitch);
    position: absolute;
    animation: noise-after 2s infinite linear alternate-reverse;
    top: 0;
    left: 2px;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
  }

  @keyframes noise-before {
    0% {
      clip: rect(61px, 9999px, 52px, 0);
    }

    5% {
      clip: rect(33px, 9999px, 144px, 0);
    }

    10% {
      clip: rect(121px, 9999px, 115px, 0);
    }

    15% {
      clip: rect(144px, 9999px, 162px, 0);
    }

    20% {
      clip: rect(62px, 9999px, 180px, 0);
    }

    25% {
      clip: rect(34px, 9999px, 42px, 0);
    }

    30% {
      clip: rect(147px, 9999px, 179px, 0);
    }

    35% {
      clip: rect(99px, 9999px, 63px, 0);
    }

    40% {
      clip: rect(188px, 9999px, 122px, 0);
    }

    45% {
      clip: rect(154px, 9999px, 14px, 0);
    }

    50% {
      clip: rect(63px, 9999px, 37px, 0);
    }

    55% {
      clip: rect(161px, 9999px, 147px, 0);
    }

    60% {
      clip: rect(109px, 9999px, 175px, 0);
    }

    65% {
      clip: rect(157px, 9999px, 88px, 0);
    }

    70% {
      clip: rect(173px, 9999px, 131px, 0);
    }

    75% {
      clip: rect(62px, 9999px, 70px, 0);
    }

    80% {
      clip: rect(24px, 9999px, 153px, 0);
    }

    85% {
      clip: rect(138px, 9999px, 40px, 0);
    }

    90% {
      clip: rect(79px, 9999px, 136px, 0);
    }

    95% {
      clip: rect(25px, 9999px, 34px, 0);
    }

    100% {
      clip: rect(173px, 9999px, 166px, 0);
    }
  }
  @keyframes noise-after {
    0% {
      clip: rect(26px, 9999px, 33px, 0);
    }

    5% {
      clip: rect(140px, 9999px, 198px, 0);
    }

    10% {
      clip: rect(184px, 9999px, 89px, 0);
    }

    15% {
      clip: rect(121px, 9999px, 6px, 0);
    }

    20% {
      clip: rect(181px, 9999px, 99px, 0);
    }

    25% {
      clip: rect(154px, 9999px, 133px, 0);
    }

    30% {
      clip: rect(134px, 9999px, 169px, 0);
    }

    35% {
      clip: rect(26px, 9999px, 187px, 0);
    }

    40% {
      clip: rect(147px, 9999px, 137px, 0);
    }

    45% {
      clip: rect(31px, 9999px, 52px, 0);
    }

    50% {
      clip: rect(191px, 9999px, 109px, 0);
    }

    55% {
      clip: rect(74px, 9999px, 54px, 0);
    }

    60% {
      clip: rect(145px, 9999px, 75px, 0);
    }

    65% {
      clip: rect(153px, 9999px, 198px, 0);
    }

    70% {
      clip: rect(99px, 9999px, 136px, 0);
    }

    75% {
      clip: rect(118px, 9999px, 192px, 0);
    }

    80% {
      clip: rect(1px, 9999px, 83px, 0);
    }

    85% {
      clip: rect(145px, 9999px, 98px, 0);
    }

    90% {
      clip: rect(121px, 9999px, 154px, 0);
    }

    95% {
      clip: rect(156px, 9999px, 44px, 0);
    }

    100% {
      clip: rect(67px, 9999px, 122px, 0);
    }
  }
  @media screen and (max-width: 768px) {
    padding: 15vh 10vw 10vh 10vw;
    .download {
      justify-content: center;
    }
    div {
      margin-bottom: 50px;
    }
    button {
      margin-top: 0;
      width: 70vw;
    }
    a {
      display: flex;
      justify-content: center;
    }
    h1 {
      font-size: 12vw;
      margin-bottom: 8vh;
      justify-content: center;
      text-align: center;
    }
    h2 {
      font-size: 7vw;
      margin-bottom: 20px;
      text-transform: uppercase;
    }
    p {
      font-size: 4.5vw;
      text-align: left;
    }
    ul {
      padding-left: 20px;
      line-height: 30px;
      margin-bottom: 30px;
    }
  }
`;

export default SRules;
