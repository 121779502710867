import Footer from 'components/Footer';
import SRessources from './style';

export default function Ressources() {
  return (
    <>
      <SRessources>Coming Soon</SRessources>

      <Footer />
    </>
  );
}
