import Footer from 'components/Footer';
import formulaire from 'assets/Formulaire-Inscription-Marathon-48h-Halloween-2022.pdf';
import SSub from './style';

export default function Sub() {
  return (
    <>
      <SSub>
        <div>
          <h2>Vous avez bien réfléchi?</h2>
          <h2>Il ne reste plus qu’un pas pour relever le défi.</h2>
          <h2>
            Télécharger et renvoyer le formulaire ci-dessous à l&lsquo;adresse
            suivante :
          </h2>
          <h2>halloween48hfilm@gmail.com</h2>
        </div>
        <div>
          <a href={formulaire} download>
            <button type="button" disabled>
              Télécharger le formulaire
            </button>
          </a>
        </div>
        <div>
          <h2>Inscription jusqu’au 26 octobre 2022, 20€ par équipe</h2>
          <h2>
            Produisez votre court-métrage d’Halloween entre le 28 et le 30
            octobre 2022.
          </h2>
        </div>
      </SSub>
      <Footer />
    </>
  );
}
