import styled from 'styled-components';

const SCountdown = styled.div`
  height: 20vh;
  color: white;
  font-size: 3vw;
  font-weight: bolder;
  text-transform: uppercase;
  @media screen and (max-width: 768px) {
    font-size: 8vw;
    text-align: center;
  }
`;

export default SCountdown;
