import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from 'assets/logo.png';
import { FaHome } from 'react-icons/fa';
import SHeader from './style';

export default function Header() {
  const [small, setSmall] = useState(false);
  const [changeTextColor, setChangeTextColor] = useState(false);
  const [placeLogo, setPlaceLogo] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', () =>
        setChangeTextColor(window.pageYOffset > 100)
      );
      window.addEventListener('scroll', () =>
        setSmall(window.pageYOffset > 100)
      );
      window.addEventListener('scroll', () =>
        setPlaceLogo(window.pageYOffset > 100)
      );
    }
  }, []);

  return (
    <SHeader>
      <div className={`header ${small ? 'small' : ''}`}>
        <Link to="/" className="home">
          <img
            className={`logo ${placeLogo ? 'placeLogo' : ''}`}
            src={Logo}
            alt="home"
          />
        </Link>
        <ul>
          <li>
            <Link
              to="/Sub"
              className={`navLink ${changeTextColor ? 'changeTextColor' : ''}`}
            >
              inscription
            </Link>
          </li>
          <li>
            <Link
              to="/FAQ"
              className={`navLink ${changeTextColor ? 'changeTextColor' : ''}`}
            >
              FAQ
            </Link>
          </li>
          <li>
            <Link
              to="/Rules"
              className={`navLink ${changeTextColor ? 'changeTextColor' : ''}`}
            >
              Régles
            </Link>
          </li>
          <li>
            <Link
              to="/Ressources"
              className={`navLink ${changeTextColor ? 'changeTextColor' : ''}`}
            >
              Ressources
            </Link>
          </li>
          <li>
            <Link to="/">
              <FaHome
                className={`navLink icon ${
                  changeTextColor ? 'changeTextColor' : ''
                }`}
              />
            </Link>
          </li>
          <li />
        </ul>
      </div>
    </SHeader>
  );
}
