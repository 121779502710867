import { useState } from 'react';
import Spinner from 'components/Spinner';
import PropTypes from 'prop-types';
import SEMbedYoutube from './style';

export default function YoutubeEmbed({ embedId, title }) {
  const [loading, setLoading] = useState(true);
  function handleVideoLoad() {
    setLoading(false);
  }
  return (
    <SEMbedYoutube>
      {loading && <Spinner />}
      <iframe
        onLoad={handleVideoLoad}
        width="853"
        height="480"
        src={`https://www.youtube.com/embed/${embedId}`}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title={`${title}`}
      />
    </SEMbedYoutube>
  );
}

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
