import moment, { duration } from 'moment';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SCountdown from './style';

export default function CountdownTimer({ targetDate, onExpired }) {
  const [timeLeft, setTimeLeft] = useState(moment('YYYY-MM-DD HH:mm:ss'));
  const [currentTime, setCurrentTime] = useState(moment());
  const updateCurrentTime = () => {
    setCurrentTime(moment());
  };
  const handleTimeout = () => {
    setTimeLeft(0);
  };

  useEffect(() => {
    const diffTime = moment(targetDate).diff(currentTime);
    const newTimeLeft = diffTime > 0 ? duration(diffTime) : null;
    setTimeLeft(newTimeLeft);

    if (newTimeLeft) {
      const timeoutId = setTimeout(handleTimeout, diffTime);
      return () => clearTimeout(timeoutId);
    }
    return () => onExpired();
  }, [currentTime, targetDate, onExpired]);

  useEffect(() => {
    const intervalId = setInterval(updateCurrentTime, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <SCountdown>
      {timeLeft ? (
        <>
          <p>{`${timeLeft.months()} months`}</p>
          <p>{`${timeLeft.days()} days`}</p>
          <p>{`${timeLeft.hours()} hours`}</p>
          <p>{`${timeLeft.minutes()} minutes`}</p>
          <p>{`${timeLeft.seconds()} seconds`}</p>
        </>
      ) : (
        <></>
      )}
    </SCountdown>
  );
}

CountdownTimer.propTypes = {
  targetDate: PropTypes.instanceOf(Date).isRequired,
  onExpired: PropTypes.instanceOf(Date).isRequired,
};
