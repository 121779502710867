import SSpinner from './style';

export default function Spinner() {
  return (
    <SSpinner>
      <div className="row">
        <div id="loader">
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
        </div>
      </div>
    </SSpinner>
  );
}
