import faq48h from 'assets/FAQ-Marathon-48h-du-Court-Metrage-d-Haloween.pdf';
import Footer from 'components/Footer';
import SFaq from './style';

export default function Faq() {
  return (
    <>
      <SFaq>
        <h1 className="glitch" data-glitch="FAQ Marathon 48h Court Métrage">
          FAQ Marathon 48h Court Métrage
        </h1>
        <div className="download">
          <button type="button">
            <a href={faq48h} download>
              Télécharger la version pdf
            </a>
          </button>
        </div>
        <div>
          <h2>
            Pourquoi organisez-vous un Marathon 48H Court-Métrage
            d&rsquo;Halloween ?
          </h2>{' '}
          <p>
            De quoi je me mêle ?! Nous avons déjà réalisé des courts-métrages
            dans le cadre de marathon 48h et nous avons eu envie de faire notre
            propre évènement, avec un thème et des contraintes qui nous
            ressemblent plus ! Et quoi de mieux que la période d&rsquo;Halloween
            pour faire un évènement sur le cinéma de genre ?
          </p>
        </div>
        <div>
          <h2>
            Mais qu&rsquo;est-ce que ça veut dire précisément
            &quot;Halloween&quot; dans Marathon 48H Court-Métrage
            d&rsquo;Halloween ? Comment fais-je pour ne pas être hors sujet ?
          </h2>
          <p>
            &quot;Halloween&quot; fait référence au thème de l&rsquo;évènement.
            Les courts-métrages développés pendant le weekend du défi doivent
            faire référence à cette fête. Par exemple, le genre du court-métrage
            peut être de l&rsquo;horreur, du fantastique, de la science-
            fiction, du thriller, du survival, il peut y avoir des monstres, du
            mystère, de l&rsquo;étrange, de l&rsquo;angoisse... Mais, une
            comédie romantique qui se passerait le soir d&rsquo;Halloween est
            aussi un exemple de film qui serait le bienvenu, si cela joue un
            minimum avec les codes d&rsquo;Halloween évidemment, il ne suffit
            pas de citer le mot &quot;halloween&quot;.
          </p>
        </div>
        <div>
          <h2>Comment composer une équipe si l&rsquo;on est seul(e) ?</h2>
          <p>
            Il est possible de déposer un message sur la page facebook dédiée à
            l’événement pour essayer de trouver une équipe sur laquelle se
            greffer. N&rsquo;oublies pas de préciser tes compétences et la ville
            où tu souhaites participer. Mais vous avez également le droit de
            participer seul(e).
          </p>
        </div>
        <div>
          <h2>Comment s&rsquo;inscrire, c&rsquo;est combien ?</h2>
          <p>
            Il faut une inscription par équipe, et non une inscription par
            personne. D&rsquo;où l&rsquo;importance de composer votre équipe en
            amont. Les frais d&rsquo;inscription sont payables une seule fois,
            pour toute l&rsquo;équipe : 20 euros. Un chef d&rsquo;équipe devra
            être désigné, cela nous permettra d&rsquo;avoir un numéro de
            téléphone à contacter durant le weekend, et cela vous permettra de
            nous faire part de vos questionnements à ce moment si besoin. Il
            faut remplir le formulaire sur le site https://halloween48hfilm.fr
          </p>
        </div>
        <div>
          <h2>
            Quelles seront les contraintes du défi Marathon 48H Court-Métrage
            d&rsquo;Halloween ?
          </h2>
          <p>
            Pour que tout le monde démarre le défi avec les mêmes contraintes,
            celles-ci seront données le vendredi 28 Octobre à 18h précise !
            <br />
            <br />
            Regardez vos e-mails ou consultez notre site ! Ce que l&rsquo;on
            peut vous dire c&rsquo;est qu&rsquo;il y aura :<br />
            <br />{' '}
            <ul>
              <li>
                une contrainte de réalisation (ex: placer un gros plan)
                <br />
                et/ou une
              </li>
              <li>
                une contrainte d&rsquo;accessoire (ex: utiliser un couteau)
                <br />
                et/ou une
              </li>
              <li>
                une contrainte scénaristique (ex: un flashback)
                <br />
                et/ou une
              </li>
              <li>une contrainte sonore (ex: une porte qui grince)</li>
            </ul>
          </p>
        </div>
        <div>
          <h2>
            Quelle est la durée d&rsquo;un court-métrage pour soumettre un film
            un film au Marathon 48H Court-Métrage d&rsquo;Halloween ?
          </h2>
          <p>
            Les courts-métrages doivent durer entre 2 et 6 minutes, sans compter
            le générique qui lui doit durer maximum une minute..
          </p>
        </div>
        <div>
          <h2>
            Je n&rsquo;ai jamais fait de film en 48 heures. Vous avez des
            conseils ?
          </h2>
          <p>
            Je recommande le planning suivant (mais il y a plusieurs manières de
            s&rsquo;y prendre) :<br /> <br />
            <ul>
              <li>
                • Vendredi soir : brainstorming, écriture du scénario, écriture
                des plans, début de la préparation
              </li>
              <li>
                • Samedi : tournage du court-métrage Dimanche : montage image,
                son, musique, reshoot, ajout de plan (risqué), upload (attention
                ça peut prendre du temps)
              </li>
              <li>
                • Dimanche : montage image, son, musique, reshoot, ajout de plan
                (risqué), upload (attention ça peut prendre du temps!)
              </li>
            </ul>
          </p>
        </div>
        <div>
          <h2>Que peut-on préparer ?</h2>
          <p>
            La préparation c&rsquo;est la clef.
            <br />
            <br />
            Il est conseillé de préparer le matériel de tournage caméra, micro,
            lampe ... et les logiciels de post-production afin de ne pas perdre
            de temps durant le weekend. Faire des courses à l&rsquo;avance pour
            acheter de la nourriture, des boissons est aussi recommandé. Par
            ailleurs, il est tout à fait possible de prévoir des accessoires qui
            vous tiennent à cœur, du maquillage, des costumes, du matériel pour
            les effets spéciaux (faux sang par exemple), des morceaux de musique
            et sons libres de droit...
          </p>
        </div>
        <div>
          <h2>Avec quel matériel peut-on filmer ?</h2>
          <p>
            N&rsquo;importe quel matériel d&rsquo;enregistrement vidéo est
            accepté : caméra professionnelle, caméscope, appareil photo, action
            camera (gopro), téléphone...
          </p>
        </div>
        <div>
          <h2>
            Mais attendez un peu, le dimanche 30 novembre, il y a un changement
            d&rsquo;heure ?!
          </h2>
          <p>
            C&rsquo;est vrai, on a pris en compte cette donnée et le rendu des
            courts-métrages doit se faire avant 19h, comme ça cela fait bien
            48h.
          </p>
        </div>
        <div>
          <h2>
            Je veux bien participer au marathon 48h mais je ne pourrais pas
            assister à la soirée de diffusion à Lille, est-ce un critère
            éliminatoire ?
          </h2>
          <p>
            La soirée de diffusion de l&rsquo;ensemble des courts métrages
            c&rsquo;est quand-même une belle occasion de passer une soirée entre
            fan de cinéma et fan de l&rsquo;horreur, alors c&rsquo;est sûr que
            c&rsquo;est bien dommage si tu n&rsquo;es pas là, cependant, ce
            n&rsquo;est pas éliminatoire. L&rsquo;événement se déroule partout
            en France, alors on comprend que toutes les équipes ne puissent pas
            se déplacer à la projection !!
          </p>
        </div>
        <div>
          <h2>
            Et après la soirée de diffusion au cinéma l&rsquo;Univers, que se
            passe t il ?
          </h2>
          <p>
            Vous possédez les droits sur vos films, diffusez les, modifiez les,
            montrez les à grand-mère et à Tonton Patrice... d&rsquo;autant
            qu&rsquo;il seront sur la chaîne youtube de l&rsquo;évènement !
          </p>
        </div>
        <div>
          <h2>Il y a quoi à gagner ?</h2>
          <p>
            C&rsquo;est la première édition du festival, nous n&rsquo;avons pas
            de partenariat super cool pour vous faire des cadeaux incroyable
            digne des chefs-d&rsquo;oeuvres que vous allez réaliser. Par contre,
            on peut vous garantir que vous passerez un weekend agréable et
            motivant et une soirée pleine de surprises !
          </p>
        </div>
        <div>
          <h2>Qui êtes-vous ?</h2>
          <p>
            Charlène et Hidéo, le binôme organisateur de l&rsquo;évènement. Nous
            sommes des réalisateurs amateurs, fans de cinéma de genre (mais pas
            que) !
          </p>
        </div>
        <div>
          <h2>Tout seuls, tout seuls ?</h2>
          <p>
            Nous sommes accompagnés par deux associations : Déclencheur souple
            et le Cinéma l&rsquo;Univers que l&rsquo;on remercie d&rsquo;ores et
            déjà de nous permettre de faire exister l&rsquo;évènement !!! Et il
            ne faut pas oublier Tréb, notre super responsable site web.
          </p>
        </div>
        <div>
          <h2>
            J&rsquo;ai d&rsquo;autres questions à vous poser je fais comment ?
          </h2>
          <p>
            Tu envoies un message à Charlène et Hidéo, le binôme
            d&rsquo;organisateur, à l&rsquo;adresse mail suivante :
            halloween48hfilm@gmail.com
            <br />
            <br />
            Dès que votre équipe est inscrite, vous aurez accès au channel
            Whatsapp de l&rsquo;évènement.
          </p>
        </div>
      </SFaq>
      <Footer />
    </>
  );
}
