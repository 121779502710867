import styled from 'styled-components';

const SEMbedYoutube = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 768px) {
    .onlyrules {padding-bottom 177.77%; /* 9:16*/
  }
  }
`;
export default SEMbedYoutube;
