import styled from 'styled-components';

const SFooter = styled.footer`
  display: flex;
  flex-flow: row nowrap;
  height: 10vh;
  bottom: 0;
  color: white;
  background: black;
  justify-content: space-evenly;
  align-items: center;
  margin-left: 10vw;
  margin-right: 10vw;
  width: 80vw;
  div {
    display: flex;
    align-items: center;
    width: 33%;
  }

  a {
    text-decoration: none;
    color: white;
  }
  a:hover {
    transform: scale(1.3);
    transition: 0.2s ease-in-out;
  }

  img {
    height: 30px;
  }
  .contact {
    align-items: right;
    justify-content: flex-end;
    display: flex;
    flex-direction: row;
  }
  .cinema {
    justify-content: center;
  }
  .socialMedia {
    display: flex;
    margin-left: 20px;
    width: 20%;
    justify-content: space-around;
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: 20vh;
    display: flex;
    align-items: center;
    margin-left: 0;
    margin-right: 0;
    justify-content: space-between;
    .copyright {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      p {
        margin-bottom: 33px;
      }
    }
    .cinema {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      p {
        margin-bottom: 20px;
      }
    }
    .contact {
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      align-items: center;
    }
    .socialMedia {
      display: flex;
      flex-direction: row;
      margin-top: 50px;
      margin-left: 0;
      width: 80%;

      align-items: center;
    }
  }
`;
export default SFooter;
