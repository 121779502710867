import universLogo from 'assets/cropped-Univers_puce_Blanc_270x270.png';
import { FaGithub } from 'react-icons/fa';
import { BsInstagram, BsFacebook } from 'react-icons/bs';
import SFooter from './style';

export default function Footer() {
  return (
    <>
      <SFooter>
        <div className="copyright">
          <p>© Halloween48h 2022 &nbsp;</p>
          <a href="https://github.com/BBriset">
            <FaGithub size={30} />
          </a>
        </div>
        <div className="cinema">
          <p>En partenariat avec le cinema L&apos;Univers &nbsp;</p>
          <a href="https://lunivers.org/">
            <img src={universLogo} alt="Cinema Univers" />
          </a>
        </div>
        <div className="contact">
          <a href="mail:halloween48hfilm@gmail.com?subjet=48hCMHalloween">
            Contact
          </a>
          <div className="socialMedia">
            <a href="https://www.facebook.com/halloween48hfilm">
              <BsFacebook size={30} />
            </a>
            <a href="https://www.instagram.com/halloween48hfilm/">
              <BsInstagram size={30} />
            </a>
          </div>
        </div>
      </SFooter>
    </>
  );
}
