import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body{
    background-color: black;
    font-family: 'Ubuntu', sans-serif;
  }
  button{font-family: 'Ubuntu', sans-serif;}
`;
export default GlobalStyle;
