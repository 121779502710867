import GlobalStyle from 'globalStyles';
import { Routes, Route } from 'react-router-dom';
import Home from 'pages/home/Index';
import Faq from 'pages/faq/Index';
import Rules from 'pages/rules/index';
import ScrollToTop from 'components/ScrollUpButton';
import GoToTop from 'components/GotoTop';
import Ressources from 'pages/ressources';
import Sub from 'pages/inscription';
import Header from './components/Header';

function App() {
  return (
    <>
      <GlobalStyle />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="FAQ" element={<Faq />} />
        <Route path="Rules" element={<Rules />} />
        <Route path="Ressources" element={<Ressources />} />
        <Route path="Sub" element={<Sub />} />
      </Routes>
      <ScrollToTop />
      <GoToTop />
    </>
  );
}

export default App;
