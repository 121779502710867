import styled from 'styled-components';

const STitleVid = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: column;
  .content {
    margin-top: 25vh;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .content p {
    font-size: 4.8vw;
    color: white;
    background-color: black;
    font-weight: bolder;
    height: auto;
    -webkit-text-stroke: 3px white;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin-top: 20px;
  }
  .filler {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  button {
    height: 50px;
    width: 250px;
    background: white;
    border-radius: 50px;
    border: none;
    -moz-animation: pulse 5s infinite ease;
    -ms-animation: pulse 5s infinite ease;
    animation: pulse 6s infinite ease;
    text-transform: uppercase;
    display: none;
  }
  button:hover {
    transform: scale(1.05);
    transition: 0.3s ease-in;
    background: #4b9f83;
    box-shadow: 0 0 5px #fff, 0 0 10px #4b9f83, 0 0 15px #4b9f83,
      0 0 20px #4b9f83, 0 0 30px #4b9f83, 0 0 40px #4b9f83, 0 0 55px #4b9f83,
      0 0 75px #4b9f83, 2px 2px 2px rgba(75, 159, 131, 0);
    -webkit-animation: none;
    -moz-animation: none;
    -ms-animation: none;
    animation: none;
  }
  .glitch {
    position: relative;
    font-size: 8vw;
    color: #e6ececfd;
    font-weight: bolder;
    cursor: default;
    text-transform: uppercase;
    z-index: 1;
  }

  .glitch:before,
  .glitch:after {
    display: block;
    content: attr(data-glitch);
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.8;
  }

  .glitch:before {
    animation: glitch-color 1.7s cubic-bezier(0.25, 0.46, 0.45, 1.94) both
      infinite;
    color: #38534a;
    z-index: -1;
  }

  .glitch:after {
    animation: glitch-color 0.9s cubic-bezier(0.25, 0.46, 0.45, 0.94) reverse
      both infinite;
    color: #4b9f83;
    z-index: -2;
  }

  @keyframes glitch-color {
    0% {
      transform: translate(0);
    }

    20% {
      transform: translate(-3px, 3px);
    }

    40% {
      transform: translate(-3px, -3px);
    }

    60% {
      transform: translate(3px, 3px);
    }

    80% {
      transform: translate(3px, -3px);
    }

    to {
      transform: translate(0);
    }
  }
  @-webkit-keyframes pulse {
    to {
      box-shadow: 0 0 5px #fff, 0 0 10px #4b9f83, 0 0 15px #4b9f83,
        0 0 20px #4b9f83, 0 0 30px #4b9f83, 0 0 40px #4b9f83, 0 0 55px #4b9f83,
        0 0 75px #4b9f83;
    }
  }
  @-moz-keyframes pulse {
    to {
      box-shadow: 0 0 5px #fff, 0 0 10px #4b9f83, 0 0 15px #4b9f83,
        0 0 20px #4b9f83, 0 0 30px #4b9f83, 0 0 40px #4b9f83, 0 0 55px #4b9f83,
        0 0 75px #4b9f83;
    }
  }
  @-ms-keyframes pulse {
    to {
      box-shadow: 0 0 5px #fff, 0 0 10px #4b9f83, 0 0 15px #4b9f83,
        0 0 20px #4b9f83, 0 0 30px #4b9f83, 0 0 40px #4b9f83, 0 0 55px #4b9f83,
        0 0 75px #4b9f83;
    }
  }
  @keyframes pulse {
    25% {
      box-shadow: 0 0 5px #fff, 0 0 10px #645f5ff6, 0 0 15px #645f5ff6,
        0 0 20px #645f5ff6, 0 0 30px #645f5ff6, 0 0 40px #645f5ff6,
        0 0 55px #645f5ff6, 0 0 75px #645f5ff6;
    }
  }
  @media screen and (max-width: 768px) {
    align-items: center;
    justify-content: center;
    text-align: center;
    .content {
      width: 90%;
      margin-top: 20vh;
      h2 {
        font-size: 10vw;
      }
      p {
        margin-top: 20px;
        font-size: 8.5vw;
      }
    }
    .filler {
      margin-top: 100px;
    }
  }
`;
export default STitleVid;
