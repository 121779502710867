import regle48h from 'assets/Regle-du-Marathon-du-Court-Metrage-d-Halloween-2022.pdf';
import Footer from 'components/Footer';
import SRules from './style';

export default function Rules() {
  return (
    <>
      <SRules>
        <h1
          className="glitch"
          data-glitch="REGLES DU Marathon 48h Court Métrage"
        >
          REGLES DU Marathon 48h Court Métrage
        </h1>
        <div className="download">
          <button type="button">
            <a href={regle48h} download>
              Télécharger la version pdf
            </a>
          </button>
        </div>
        <div>
          <h2>GUIDE DE SURVIE</h2>
          <p>
            Règles pour survivre à un marathon de 48h film spécial Halloween !
            <br />
            <br />
            Une règle plutôt simple :<br />
            <br /> vous formez votre propre équipe avec votre propre matériel et
            vous réalisez un court métrage en 48h, avec un thème déjà connu à
            l’avance : Halloween. Votre court devra durer entre 2 et 6 minutes.
            Des contraintes seront données, communes à toutes les équipes
            participantes, vendredi 28 octobre 2022 à 18h et vous aurez jusqu’au
            dimanche 30 octobre à 19h pour survivre au défi ! Tous les films
            seront ensuite diffusés au Cinéma l’univers le dimanche 06 novembre
            2022 pour une soirée de l&apos;horreur !
          </p>
        </div>
        <div>
          <h2>Inscription :</h2>
          <p>
            Les équipes doivent s’inscrire avant le mercredi 26 octobre 2022 via
            le formulaire d&apos;inscription disponible sur notre site
            https://halloween48hfilm.fr.
            <br />
            <br /> Une seule inscription par équipe, avec la liste des membres
            (hors comédien.ne.s) est nécessaire.
            <br />
            <br /> Le tarif est de 20 euros par équipe. Le paiement se fait par
            compte Paypal avec la mention « marathon 48h Halloween et NOM DE
            L’ÉQUIPE ». La réception du paiement valide votre inscription.{' '}
            <br />
            <br />
            Un chef d&apos;équipe doit être désigné et doit indiquer son numéro
            de téléphone pour que l&apos;on puisse vous contacter (comme on est
            sympa, on vous appelera le samedi 29 octobre pour prendre de vos
            nouvelles)
            <br />
            <br /> Il est possible de s&apos;inscrire en solo, et jusqu&apos;à
            13 membres (hors comédien.ne.s) par équipe.
          </p>
        </div>
        <div>
          <h2>Contraintes surprises :</h2>
          <p>
            Vous connaissez la thématique, Halloween, mais par contre, vous ne
            connaitrez les autres contraintes que vendredi 28 octobre 2022 à 18h
            !! Chaque équipe doit faire apparaitre TOUS les éléments imposés
            dans son film. Les contraintes sont identiques pour tout le monde.
            <br />
            <br />
            Pour vous donner une idée :
            <br />
            <br />
            <ul>
              <li>
                une contrainte de réalisation (ex: placer un gros plan)
                <br />
                et/ou une
              </li>
              <br />
              <li>
                une contrainte d&rsquo;accessoire (ex: utiliser un couteau)
                <br />
                et/ou une
              </li>
              <br />
              <li>
                une contrainte scénaristique (ex: un flashback)
                <br />
                et/ou une
              </li>
              <br />
              <li>une contrainte sonore (ex: une porte qui grince)</li>
            </ul>
          </p>
        </div>
        <div>
          <h2>Générique :</h2>
          <p>
            Le film doit durer entre 2 et 6 minutes (hors générique). Le
            générique ne doit pas dépasser une minute. Donc le film ne doit pas
            dépasser 7 minutes générique compris. <br />
            <br />
            La fin du générique doit se terminer par : Ce film a été réalisé
            lors du Marathon 48h du court métrage Halloween de 2022.
          </p>
        </div>
        <div>
          <h2>Avant le film :</h2>
          <p>
            5 secondes de noir puis un carton avec : Le nom de l&apos;équipe, le
            nom du film, la date. Puis un carton : &apos;Film réalisé dans le
            cadre du Marathon 48h du court métrage Halloween 2022&apos; puis 2
            secondes de noir avant le début du film. Pour toute projection en
            ligne ou en salle le film doit garder les cartons de début et de
            fin.
          </p>
        </div>
        <div>
          <h2>Précisions des conditions de tournage :</h2>
          <p>
            L’intégralité de la création doit avoir lieu dans la période
            officielle (scénario, décors, tournage, montage, etc.). En revanche,
            nous vous conseillons de préparer en amont une équipe, de trouver le
            matériel de tournage, des comédiens et comédiennes, et, repérer ou
            réserver des lieux de tournage. <br />
            <br />
            Toutes les images doivent impérativement être tournées pendant la
            période du défi. <br />
            <br />
            Les photos sont autorisées si vous possédez les droits, et ne sont
            pas nécessairement prises lors du week-end de compétition.
          </p>
        </div>
        <div>
          <h2> Formats autorisés :</h2>
          <p>
            L’évènement est ouvert à plusieurs formats ; prise de vue réelle,
            animation, documentaire.
            <br />
            <br /> Tous les supports sont autorisés pour tourner, caméra,red ,
            camescope, appareil photo du copain, GoPro, téléphone de ta cousine,
            etc.
          </p>
        </div>
        <div>
          <h2> Provenance :</h2>
          <p>
            Le film peut être fait n&apos;importe où en France, ce n&apos;est
            pas un événement strictement réservé aux nordistes.
          </p>
        </div>
        <div>
          <h2> Rendu le dimanche soir :</h2>
          <p>
            Vous devez nous envoyer votre court via un lien de téléchargement
            par un service type wetransfers, suisstransfers ou google cloud à
            l&apos;adresse suivante : halloween48hfilm@gmail.com.
            <br />
            <br />
            L&apos;heure de rendu maximal est 19h le dimanche 30 octobre 2022
            soir. Pensez à anticiper les temps d&apos;upload de vos film.
          </p>
        </div>
        <div>
          <h2> Les droits : </h2>
          <p>
            Nous autorisons l&apos;utilisation de toutes musiques dont vous
            possédez des droits ou dont vous pouvez justifier qu&apos;ils sont
            libres de droits (origine citée dans le générique a minima).
            <br />
            <br />
            Attention à la loi des droits d&apos;auteurs ! Vous n&apos;avez pas
            le droit de chanter, siffler... une musique dont vous n&apos;avez
            pas les droits.
            <br />
            <br /> Les équipes cèdent à l&apos;association sans contrepartie, le
            droit de diffusion des films pour la soirée halloween du dimanche 06
            novembre et pour une diffusion en ligne. Elles cèdent le droit de
            reproduction d’images ou d’extraits de moins d’une minute de ces
            films pour diffusion dans la presse ou sur les réseaux sociaux.
            Enfin, elles cèdent la diffusion de leur film sur le site dédié à
            l&apos;évenement,qui se propose de réunir l’ensemble des productions
            sans contrepartie, pour une durée de 10 ans.
            <br />
            <br /> Selon le code de la propriété intellectuelle, toutes les
            musiques ou photos utilisées sont celles dont vous disposez des
            droits, ou libres de droits. En cas de non-respect des droits
            d’auteur, le festival décline toute responsabilité. Ces infractions
            donnent lieu à des sanctions pénales allant de 3 750 € à 30 000€
            d’amende et 6 mois d’emprisonnement (article L. 335-3-1 et L.335-3-2
            CPI).
          </p>
        </div>
        <div>
          <h2> Format du fichier du film :</h2>
          <p>
            <ul>
              <li>• Envoi en ligne </li>
              <li>
                • Type de fichier: MPEG 4 (.mp4), MPEG 4 (.m4v), Quicktime
                (.mov)
              </li>
              <li> • Resolution: HD 1080 (1920x1080)</li>
              <li> • Ratio: 16:9 </li>
              <li>
                • Frame Rate: 24 FPS, 24 FPS (23.976), 25 FPS, 30 FPS (29.97)
              </li>
              <li> • Codec Audio: AAC (.aac), WAV (.wav), MPEG4 (.mp4)</li>
              <li> • Taille maximale de fichier: 2.0GB de préférence</li>
            </ul>
          </p>
          <br />
          <p>
            Les niveaux de son doivent être relativement égaux pendant toute la
            durée du film (-6db moyen).
          </p>
          <br />
          <p>
            Encore une fois, attention à la durée d’exportation et d’envoi de
            vos films ! Il faut penser à les anticiper pour survivre aux 48h !
            Et n&apos;oubliez pas de nous envoyer en même temps que votre film,
            attestations obligatoires que l&apos;on vous aura fait parvenir par
            email en même temps que les contraintes vendredi 28 octobre 2022 :)
          </p>
        </div>
        <div>
          <h2> Attention :</h2>
          <p>
            Nous nous réservons le droit d&apos;appeler l&apos;équipe et de ne
            pas diffuser un film si le contenu est jugé raciste, LGBTIphobe,
            mysogyne, pornographique, ... <br />
            <br />
            Aucun animal ne doit être maltraité ou tué lors du week-end de
            compétition (on ne force pas son chat à jouer dracula, on le laisse
            flanner pendant que vous, vous bossez !).
          </p>
        </div>
        <div>
          <h2>
            Soirée de diffusion du dimanche 06 novembre 2022 à Lille au cinéma
            l&apos;Univers :
          </h2>
          <p>
            Toutes les équipes ayant rendu leur film avant 19h le dimanche 30
            octobre pourront le voir diffuser la soirée Halloween du dimanche 06
            novembre. Vous êtes ensuite les bienvenus à cette soirée ! Bar dispo
            pour faire connaissance et échanger autour de vos
            chefs-d&apos;oeuvre (aucun doute !)
          </p>
        </div>
        <div>
          <h2>Adresse de la soirée de diffusion :</h2>
          <p>
            Cinéma L&apos;Univers, 16 Rue Georges Danton, 59000 Lille Métro le
            plus proche : Porte de Valenciennes, ligne 2 (rouge)
          </p>
        </div>
      </SRules>
      <Footer />
    </>
  );
}
