import Footer from 'components/Footer';
import TitleVid from 'components/TitleVid';
import VideosList from 'components/videoList';
import CountdownTimer from 'components/Countdown';
import { useState } from 'react';
import moment from 'moment';

import SHome from './style';

export default function Home() {
  const [isExpired, setIsExpired] = useState(false);

  const targetDate = moment('2023-03-02 16:09:00', 'YYYY-MM-DD HH:mm:ss');

  const handleExpired = () => {
    setIsExpired(true);
  };

  return (
    <SHome>
      <TitleVid />

      <VideosList playlistId="PLe7JqXUDav1J1FKESX7HxRUSIgkXZHoj3" />
      <div>
        {isExpired ? (
          <>
            <p>Le compteur de temps a expiré !</p>
          </>
        ) : (
          <CountdownTimer targetDate={targetDate} onExpired={handleExpired} />
        )}
      </div>

      <Footer />
    </SHome>
  );
}
