import styled from 'styled-components';

const SSub = styled.section`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-evenly;
  background: linear-gradient(
    180deg,
    #000000 20%,
    rgba(56, 83, 74, 1) 80%,
    rgba(75, 159, 131, 1) 100%
  );
  color: white;
  height: 65vh;
  margin-top: 25vh;

  h2 {
    font-size: 1.6vw;
    font-weight: bolder;
    width: 60vw;
    text-align: center;
    margin-bottom: 3vh;
  }

  button {
    height: 50px;
    width: 250px;
    background: white;
    border-radius: 50px;
    border: none;
    box-shadow: 0 0 5px #fff, 0 0 10px #645f5ff6, 0 0 15px #645f5ff6,
      0 0 20px #645f5ff6, 0 0 30px #645f5ff6, 0 0 40px #645f5ff6,
      0 0 55px #645f5ff6, 0 0 75px #645f5ff6;
    text-transform: uppercase;
  }
  button:disabled {
    cursor: not-allowed;
  }
  button:disabled:hover {
    background: #645f5ff6;
    color: white;
    transform: none;
    box-shadow: none;
    transition: none;
  }
  button:hover {
    transform: scale(1.05);
    transition: 0.3s ease-in;
    background: #4b9f83;
    box-shadow: 0 0 5px #fff, 0 0 10px #4b9f83, 0 0 15px #4b9f83,
      0 0 20px #4b9f83, 0 0 30px #4b9f83, 0 0 40px #4b9f83, 0 0 55px #4b9f83,
      0 0 75px #4b9f83, 2px 2px 2px rgba(75, 159, 131, 0);
  }
  h1 {
    font-size: 5vw;
    margin-bottom: 12vh;
    text-transform: uppercase;
  }
  @media screen and (max-width: 768px) {
    margin-top: 10vh;
    height: 90vh;
    h2 {
      font-size: 6vw;
    }
  }
`;

export default SSub;
