import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import YoutubeEmbed from 'components/EmbedYoutube';
import apiKey from '../../conf';
import SvideosList from './style';

export default function VideoList({ playlistId }) {
  const [videos, setVideos] = useState([]);
  useEffect(() => {
    const getPlaylistVideos = async () => {
      const response = await fetch(
        `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${playlistId}&maxResults=50&key=${apiKey}`
      );
      const data = await response.json();
      const playlistItems = data.items;
      if (playlistItems) {
        const videosList = [];
        playlistItems.forEach(function fetch(item) {
          const { title } = item.snippet;
          const { videoId } = item.snippet.resourceId;
          const { description } = item.snippet;
          videosList.push({ title, videoId, description });
        });
        setVideos(videosList);
      }
    };
    getPlaylistVideos();
  }, [playlistId]);

  return (
    <SvideosList>
      <h1>
        Les courts Métrages
        <br /> réalisés lors de l&#39;édition 2022!
      </h1>
      <ul>
        {videos.map((video) => (
          <li key={video.videoId}>
            <h2> {`${video.description}`}</h2>
            <div className="clip">
              <YoutubeEmbed
                embedId={`${video.videoId}`}
                title={`${video.title}`}
              />
            </div>
          </li>
        ))}
      </ul>
    </SvideosList>
  );
}

VideoList.propTypes = {
  playlistId: PropTypes.string.isRequired,
};
