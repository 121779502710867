import { Link } from 'react-router-dom';
import STitleVid from './style';

export default function TitleVid() {
  return (
    <STitleVid>
      <div className="content">
        <h2 className="glitch" data-glitch="Marathon 48h">
          Marathon 48h
        </h2>
        <h2 className="glitch" data-glitch="Court Métrage">
          Court Métrage
        </h2>
        <h2 className="glitch" data-glitch="Halloween">
          Halloween
        </h2>
        <p>LILLE - 28-30 OCTOBRE 2022</p>
      </div>
      <div className="filler">
        <Link to="/Sub">
          <button type="button">inscription</button>
        </Link>
      </div>
    </STitleVid>
  );
}
